































































































































































































































import Vue from "vue";
import { mapState } from "vuex";
import { download } from "@/utils";

export default Vue.extend({
  computed: {
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      images: "images",
      oss: "oss",
      url: "url"
    }),
    titleList() {
      return [
        this.$t("cyberboard.shortTitle01"),
        this.$t("cyberboard.shortTitle02"),
        this.$t("cyberboard.shortTitle03"),
        this.$t("cyberboard.shortTitle04"),
        this.$t("cyberboard.shortTitle05"),
        this.$t("cyberboard.shortTitle06"),
        this.$t("cyberboard.shortTitle07"),
        this.$t("cyberboard.shortTitle08"),
        this.$t("cyberboard.shortTitle09"),
        this.$t("cyberboard.shortTitle10"),
        this.$t("cyberboard.shortTitle11"),
        this.$t("cyberboard.shortTitle12"),
        this.$t("cyberboard.shortTitle13"),
        this.$t("cyberboard.shortTitle14"),
        this.$t("cyberboard.shortTitle15"),
        this.$t("cyberboard.shortTitle16"),
        this.$t("cyberboard.shortTitle17"),
        this.$t("cyberboard.shortTitle18"),
        this.$t("cyberboard.shortTitle19"),
        this.$t("cyberboard.shortTitle20"),
        this.$t("cyberboard.shortTitle21"),
        this.$t("joinUs"),
        "FOOTER"
      ];
    }
  },
  methods: {
    onDownload() {
      download(this.oss.pdf_cyberboard);
    }
  }
});
